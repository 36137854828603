<template>
    <div class="modal profile_modal share_playbook" v-if="modelValue">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <h1 class="m-0 pb-4">Sequence User</h1>
                <button class="close_btn" @click="closeModal()"><i class="fas fa-times"></i></button>
            </div>
            <div class="modal_body">
                <div class="tab_wpr mt-3">
                    <div class="result_wpr">
                        <div class="actions">
                            <ul>
                                <li class="optionDrops sort_list" @click="togglePerPageFilter()" v-click-outside="closePerPageDropdown">
                                    {{ params.per_page }} <i  class="fas fa-angle-down"></i>
                                    <page-filter v-model="params.per_page" :type="2" :is-dropdown="true" ref="sequence-user-per-page-filter" />
                                </li>
                                <li v-if="sequenceUsers.data ? sequenceUsers.data.length : ''" class="delete" @click="handleDeleteSequenceUser(selectedUsers)"><i class="fas fa-trash-alt danger m-0"></i></li>
                                <li><input type="text" class="p-0" @input="isTyping = true" v-model.trim="params.search" placeholder="Search"/>
                                    <i class="fas fa-search"></i>
                                </li>
                                <li class="list_info">
                                    {{ sequenceUsers.from ? sequenceUsers.from : 0 }} - {{ sequenceUsers.to ? sequenceUsers.to : 0 }} of <span>{{ sequenceUsers.total ? sequenceUsers.total : 0 }}</span>
                                </li>
                            </ul>
                        </div>
                        <div v-if="loader"><line-loader /></div>
                        <table class="standard">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Last Completed</th>
                                    <th>Status</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody v-if="sequenceUsers.data && sequenceUsers.data.length">
                                <tr v-for="(sequenceUser, m) in sequenceUsers.data" :key="m">
                                    <td>
                                        <label :for="`sequence-sequenceUser-${sequenceUser.id}`" class="checkbox">
                                            <input type="checkbox" :id="`sequence-sequenceUser-${sequenceUser.id}`" :value="sequenceUser.id" v-model="selectedUsers" hidden>
                                            <span><i class="fas fa-check"></i></span>
                                        </label>
                                    </td>
                                    <td>
                                        <div class="user_wpr" @click="selectedContact = sequenceUser.contact; profile = true">
                                            <h4>{{ sequenceUser.contact && sequenceUser.contact.name ? sequenceUser.contact.name : '' }}</h4>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="email_wpr">{{  sequenceUser.contact && sequenceUser.contact.email ? sequenceUser.contact.email : ''}}</div>
                                    </td>
                                    <td>{{ sequenceUser.email_order}}</td>
                                    <td>{{ sequenceUser.completed > 0 ? 'Completed' : 'Ongoing' }}</td>
                                    <td>
                                        <ul class="action_list">
                                            <li @click="handleDeleteSequenceUser([sequenceUser.id])"><i class="fas fa-trash-alt"></i></li>
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody class="no_record" v-else>
                                <tr>
                                    <td colspan="6" class="px-4">No Records Found</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="pagination pb-4 mt-4" v-if="sequenceUsers.data && sequenceUsers.data.length">
                        <pagination v-model="params.page" :pages="sequenceUsers.last_page" @update:modelValue="handlePagination" />
                    </div>
                </div>
            </div>
            <div class="modal_footer">
                <button class="btn cancel_btn" @click="closeModal()">Cancel</button>
            </div>
        </div>
    </div>
    <profile-component v-model="profile" :contact="selectedContact" />
</template>

<script>
    import {mapState, mapActions} from 'vuex';
    import { defineAsyncComponent } from 'vue'

    const ProfileComponent = defineAsyncComponent(() => import('@/pages/contact/components/Profile'))

    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'
    import Toastr from '@/utils/Toastr'

    export default {
        data () {
            return {
                params: {
                    per_page: 5,
                    page: 1,
                    search: '',
                    sequence_id: '',
                },
                isTyping: false,
                selectedUsers: [],
                profile: false,
                selectedContact: {},
            }
        },

        props: {
            modelValue: Boolean,
            sequence: Object,
        },

        emit: ['update:modelValue'],

        watch: {
            modelValue (val) {
                const vm = this;

                if (val) {
                    vm.resetParams();

                    vm.getSequenceUser(vm.params);
                }
            },

            'params.per_page' () {
                const vm = this;

                vm.params.page = 1;
                vm.getSequenceUser(vm.params);
            },

            'params.search' () {
                const vm = this;

                setTimeout(() => {
                    vm.isTyping = false;
                }, 1500)
            },

            isTyping (val) {
                const vm = this;

                if (!val) {
                    if (vm.params.search != null) {
                        if (vm.params.search.length == 0 || vm.params.search.length >= 2) {
                            vm.params.page = 1;

                            vm.getSequenceUser(vm.params);
                        }
                    }
                }
            },
        },

        components: {
            ProfileComponent,
        },

        computed: mapState({
            loader: state => state.sequenceModule.sequenceComponentLoader,
            sequenceUsers: state => state.sequenceModule.sequenceUsers,
        }),

        methods: {
            ...mapActions({
                getSequenceUser: 'sequenceModule/getSequenceUser',
                deleteSequenceUser: 'sequenceModule/deleteSequenceUser',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false)
            },

            togglePerPageFilter () {
                const vm = this;

                const filter = vm.$refs['sequence-user-per-page-filter'];

                if (filter) {
                    filter.dropdown = !filter.dropdown;
                }
            },

            closePerPageDropdown () {
                const vm = this;

                const filter = vm.$refs['sequence-user-per-page-filter'];

                if (filter) {
                    filter.dropdown = false;
                }
            },

            handlePagination (page) {
                const vm = this;

                vm.params.page = page;
                vm.getSequenceUser(vm.params);
            },

            handleDeleteSequenceUser (ids) {
                const vm = this;

                if (ids.length) {
                    const options = Helper.swalConfirmOptions('Are you sure?', `This action is permanent. You will not be able to recover ${ids.length > 1 ? 'these sequence users' : 'this sequence user'}`, 'Delete');

                    options.preConfirm = function () {
                                            return vm.deleteSequenceUser({ ids, 'sequence_id': vm.sequence.id  }).then((result) => {
                                                    if (result) {
                                                        vm.getSequenceUser(vm.params);
                                                        vm.selectedUsers = [];

                                                        if (vm.$parent.$parent && vm.$parent.$parent.refreshSequence) {
                                                            vm.$parent.$parent.refreshSequence();
                                                        } else {
                                                            vm.$parent.refreshSequence();
                                                        }
                                                    }
                                            });
                                        };

                    Swal.fire(options);
                } else {
                    Toastr.error('Please select at least one User');
                }
            },

            resetParams () {
                const vm = this;

                vm.params = {
                    per_page: 5,
                    page: 1,
                    search: '',
                    sequence_id: vm.sequence.id,
                };

                vm.selectedUsers = [];
            }
        }
    }
</script>

<style  scoped>
    .share_playbook .modal_container {
        height: 640px;
    }

    .share_playbook .modal_container .modal_header {
        padding: 20px 30px 0;
    }

    .share_playbook .modal_container .modal_body {
        padding: 0 30px;
        flex-direction: column;
    }

    .share_playbook .tab_wpr {
        width: 100%;
    }

    .share_playbook .result_wpr  {
        width: 100%;
    }

    .result_wpr table.standard td:first-child, .result_wpr table.standard th:first-child {
        width: auto;
        text-align: left;
        padding-left: 10px;
    }

    .result_wpr table.standard tbody.no_record td:first-child {
        width: auto;
        text-align: center;
    }

    .modal_footer {
        border-radius: 0 0 12px 12px;
    }

    .action_list li i.fa-trash-alt{
        color: #eb1414;
    }
</style>
